"use client";

/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useCallback, useEffect } from "react";
import { createGenericContext } from "@/utils/createGenericContext";
import { useSession } from "next-auth/react";
import { useMediaQuery } from "./useMediaQuery";
const [useIntercomContext, Provider] = createGenericContext<{
  isIntercomHidden: React.MutableRefObject<boolean>;
}>({
  isIntercomHidden: {
    current: false
  }
});
export const IntercomProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const isIntercomHidden = React.useRef<boolean>(false);
  return <Provider value={{
    isIntercomHidden
  }} data-sentry-element="Provider" data-sentry-component="IntercomProvider" data-sentry-source-file="useHideIntercom.tsx">{children}</Provider>;
};
export const useHideIntercom = (props: {
  onlyIfLoggedIn?: boolean;
  onlyOnMobile?: boolean;
} = {}) => {
  const session = useSession();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const {
    isIntercomHidden
  } = useIntercomContext();
  const prev = React.useRef<boolean>(true);
  useEffect(() => {
    if (typeof window === "object" && typeof document === "object" && window.Intercom) {
      if (session.status === "authenticated" || !props.onlyIfLoggedIn) {
        try {
          prev.current = isIntercomHidden.current;
          window?.Intercom?.("update", {
            hide_default_launcher: true,
            alignment: "right"
          });
          isIntercomHidden.current = true;
        } catch (e) {
          console.error(e);
        }
      }
      if (props.onlyOnMobile && isMobile) {
        try {
          prev.current = isIntercomHidden.current;
          window?.Intercom?.("update", {
            hide_default_launcher: true,
            alignment: "right"
          });
          isIntercomHidden.current = true;
        } catch (e) {
          console.error(e);
        }
      }
    }
    return () => {
      if (typeof window === "object" && typeof document === "object" && window.Intercom) {
        try {
          window?.Intercom?.("update", {
            hide_default_launcher: prev.current,
            alignment: "right"
          });
        } catch (e) {
          console.error(e);
        }
      }
    };
  }, [session.status, props.onlyIfLoggedIn, props.onlyOnMobile, isMobile, isIntercomHidden]);
};
export const useToggleIntercom = () => {
  const {
    isIntercomHidden
  } = useIntercomContext();
  const showIntercom = useCallback(() => {
    if (typeof window === "object" && typeof document === "object" && window.Intercom) {
      try {
        window?.Intercom?.("update", {
          hide_default_launcher: false,
          alignment: "right"
        });
        isIntercomHidden.current = false;
      } catch (e) {
        console.error(e);
      }
    }
  }, [isIntercomHidden]);
  const hideIntercom = useCallback(() => {
    if (typeof window === "object" && typeof document === "object" && window.Intercom) {
      try {
        window?.Intercom?.("update", {
          hide_default_launcher: true,
          alignment: "right"
        });
        isIntercomHidden.current = true;
      } catch (e) {
        console.error(e);
      }
    }
  }, [isIntercomHidden]);
  return {
    showIntercom,
    hideIntercom,
    isIntercomHidden
  };
};
export const HideIntercom = () => {
  useHideIntercom();
  return null;
};